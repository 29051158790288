/**
 * @returns {number} The height of the window
 */
const getScreenHeight = () => {
    return window.innerHeight
}

/**
 * @returns {number} The width of the window
 */
const getScreenWidth = () => {
    return window.innerWidth
}

/**
 * @param {*} element
 * @returns element offset relative to window top
 */
const getTopOffset = (element) => {
    let offsetTop = 0
    while (element) {
        offsetTop += element.offsetTop
        element = element.offsetParent
    }
    return offsetTop
}

const isInView = (element) => {
    const screenTop = window.scrollY
    const screenBottom = getScreenHeight() + screenTop

    const top = getTopOffset(element)
    const bottom = top + element.offsetHeight

    return bottom > screenTop && top < screenBottom
}

const minMax = (min, max, val) => {
    return Math.min(Math.max(val, min), max)
}

const easedMovement = async (
    startDistance,
    finalDistance,
    time,
    onProgress = () => {},
    onFinish = () => {}
) => {
    const ease = (x) => {
        return 1 - Math.pow(1 - x, 4)
    }

    const startTime = Date.now()

    const distance = Math.abs(finalDistance - startDistance)
    const dir = finalDistance - startDistance > 0 ? 1 : -1

    var moved = 0

    const easeFrame = () => {
        const progress = (Date.now() - startTime) / time

        const easedDistance = ease(progress) * distance - moved
        moved += easedDistance

        if (easedDistance <= 0 || Math.round(moved) >= distance) {
            onFinish()
            return
        }

        onProgress(moved * dir, easedDistance * dir, dir)

        requestAnimationFrame(easeFrame)
    }
    requestAnimationFrame(easeFrame)
}

/**
 * Scrolls window to given offset ( Similar functionality to scrolling to anchor, but without the anchor )
 * @param {*} endOffset The target top offset
 * @param {*} time The time it takes to scroll to the target offset
 * @param {*} onFinish Callback function that is called when the scroll is finished
 */
const easedScroll = async (endOffset, time, onFinish = () => {}) => {
    const startOffset = window.pageYOffset

    easedMovement(
        startOffset,
        endOffset,
        time,
        (moved) => {
            window.scrollBy(0, startOffset + moved)
        },
        onFinish
    )
}

/**
 * Scrolls to give anchor
 * @param {*} anchor Element selector
 */
const scrollToAnchor = (anchor) => {
    const target = document.querySelector(anchor)

    // const offset =
    //     getTopOffset(target) -
    //     (parseInt(
    //         window.getComputedStyle(target).paddingTop.replace('px', '')
    //     ) || 100)

    const offset = getTopOffset(target)

    window.scrollTo({
        top: offset,
        behavior: 'smooth'
    })
}

/**
 * Override default anchor scroll at link click
 */
const anchors = () => {
    document.querySelectorAll(`a[href^="#"]`).forEach((el) => {
        var url = el.getAttribute('href')

        var page = url.split('#').shift()

        if (
            !(page.trim() == '' || page == window.location.href.split('#')[0])
        ) {
            return
        }

        el.addEventListener('click', (e) => {
            e.preventDefault()

            scrollToAnchor(url)
        })
    })

    let l = window.location.href
    if (l.includes('#')) {
        scrollToAnchor(`#${l.split('#').pop()}`)
    }
}

/**
 * Adds root css variable with current screen height
 */
const screenHeight = () => {
    document
        .querySelector(':root')
        .style.setProperty('--screen-h', `${getScreenHeight()}px`)
}

/**
 * Init all events / functions that need setup
 */
const initUtils = () => {
    // THIS BASTARD BREAKS SCROLLING TO LOWER PLACED ANCHORS ON MOBILE SO I COMMENTED IT OUT FOR THE TIME BEING
    // anchors()

    screenHeight()
    window.addEventListener('resize', screenHeight)
}

export {
    initUtils,
    getScreenHeight,
    getScreenWidth,
    getTopOffset,
    easedMovement,
    easedScroll,
    isInView,
    minMax,
    scrollToAnchor
}
