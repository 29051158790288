// ------------
// Sticky menu highlighter
// ------------
const initMenuHighlighter = () => {
    // const getHeight = () => {
    //     return window.innerHeight
    // }

    // let navbar = document.querySelector('.js-highlighted-menu')
    // const links = navbar.querySelectorAll('a.js-highlight')
    // const navOffsets = []
    // for (let link of links) {
    //     let href = link.getAttribute('href')
    //     let targetEl = document.querySelector(href)

    //     if (!targetEl) {
    //         return
    //     }

    //     navOffsets.push(targetEl.offsetTop)
    // }

    // if (!navbar) {
    //     return
    // }

    // document.addEventListener(
    //     'scroll',
    //     (e) => {
    //         const navElements = links
    //         let selectedIndex

    //         if (
    //             window.scrollY - getHeight() / 2 >
    //             navOffsets[navOffsets.length - 1]
    //         ) {
    //             for (const navEl of navElements) {
    //                 navEl.classList.remove('active')
    //             }
    //             return
    //         }

    //         for (const [index, navEl] of navOffsets.entries()) {
    //             if (window.scrollY + getHeight() / 2 >= navEl) {
    //                 selectedIndex = index
    //             }
    //         }

    //         for (const navEl of navElements) {
    //             navEl.classList.remove('active')
    //         }

    //         if (selectedIndex !== undefined) {
    //             navElements[selectedIndex].classList.add('active')
    //         }
    //     },
    //     { passive: true }
    // )
}

export { initMenuHighlighter }
