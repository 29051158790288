import { getScreenHeight, getTopOffset, isInView, minMax } from './utils'

const resize = (el) => {
    el.style.setProperty('--text-size', `${el.offsetWidth}px`)
}
const setProgress = (el, progress) => {
    el.style.setProperty('--progress', `${progress}`)
}

const initScrollText = () => {
    const texts = document.querySelectorAll('.js-scroll-text')

    const scroll = () => {
        const screenTop = window.scrollY
        const screenH = getScreenHeight()

        texts.forEach((text) => {
            if (!isInView(text)) {
                setProgress(text, 0)
                return
            }

            const topOffset = getTopOffset(text)
            const middle = topOffset + text.offsetHeight / 2

            const progress = (screenTop - (middle - screenH)) / screenH / 2

            setProgress(text, progress)
        })
    }

    const initialAnim = () => {
        texts.forEach((text) => {
            text.animate([{ opacity: '0.5' }], {
                duration: 150
            }).onfinish = () => {
                text.style.opacity = 0.5
            }
        })
    }

    texts.forEach((text) => {
        text.style.setProperty('--progress', `0`)

        resize(text)
        window.addEventListener('resize', () => resize(text))

        text.style.transform =
            'translateX(calc((((var(--text-size)/2) + 100vw) * var(--progress)) - (var(--text-size)/4)))'
    })

    scroll()
    initialAnim()
    window.addEventListener('scroll', () => scroll())
}

export { initScrollText }
