import { isInView } from './utils'

const countUp = (el, from, to) => {
    var duration = 2700
    var startTime = performance.now()

    function formatNumber(n) {
        if (n >= 1000000000) {
            return (n / 1000000000).toFixed(2) + ' mld.'
        } else if (n >= 1000000) {
            return (n / 1000000).toFixed(0) + ' mil.'
        } else {
            return n.toString()
        }
    }

    function updateCounter() {
        var now = performance.now()
        var progress = Math.min((now - startTime) / duration, 1) // calculate progress as a fraction between 0 and 1
        var count = Math.floor(from + progress * (to - from)) // calculate current count based on progress
        el.innerHTML = formatNumber(count)

        if (progress < 1) {
            requestAnimationFrame(updateCounter) // continue the animation if it's not done yet
        }
    }

    updateCounter()
}

const initCountUp = () => {
    const options = {
        root: null, // Use the viewport as the root
        threshold: 0.8 // Trigger when 50% of the element is visible
    }
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                let el = entry.target
                countUp(el, Number(el.dataset.from), Number(el.dataset.to))
                observer.unobserve(entry.target)
            }
        })
    }, options)

    document.querySelectorAll('.js-count-up').forEach((el) => {
        observer.observe(el)
    })
}

export { initCountUp }
