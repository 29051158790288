/*
 * Event Popup LOCAL STORAGE EDITION - session storage does not work for some reason
 */
const initEventPopup = (onClose) => {
    let overlay = document.querySelector('.js-eventBackground')
    let popup = document.querySelector('.js-eventPopup')

    if (!popup) {
        onClose()
        return
    }

    function getWithExpiry(key) {
        const itemStr = localStorage.getItem(key)
        // if the item doesn't exist, return null
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()
        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(key)
            return null
        }
        return item.value
    }

    function setWithExpiry(key, value, ttl) {
        const now = new Date()
        // `item` is an object which contains the original value
        // as well as the time when it's supposed to expire
        const item = {
            value: value,
            expiry: now.getTime() + ttl
        }
        localStorage.setItem(key, JSON.stringify(item))
    }

    function openPopup() {
        if (getWithExpiry('seen') == 'seen') {
            onClose()
            return
        }
        setWithExpiry('seen', 'seen', 604800)
        overlay.classList.remove('hidden')
        popup.classList.remove('hidden')

        overlay.animate({ opacity: 1 }, { duration: 400 }).onfinish = () => {
            overlay.classList.remove('opacity-0')
        }
        popup.animate({ opacity: 1 }, { duration: 400 }).onfinish = () => {
            popup.classList.remove('opacity-0')
        }
    }

    function closePopup() {
        overlay.classList.add('hidden')
        popup.classList.add('hidden')

        onClose()
    }

    document
        .querySelector('.js-popupCloser')
        .addEventListener('click', closePopup)
    overlay.addEventListener('click', closePopup)
    openPopup()
}

export { initEventPopup }
