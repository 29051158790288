const menu = document.querySelector('header')
const mobileMenu = document.querySelector('.js-menu-mobile')

const scroll = () => {
    const offsetY = window.offsetY || window.pageYOffset

    if (offsetY > menu.getBoundingClientRect().height / 2) {
        menu.classList.add('stick')
    } else {
        menu.classList.remove('stick')
    }
}

const closeMenu = () => {
    document.body.style.overflow = 'unset'
    mobileMenu.classList.add('translate-x-full')
}
const openMenu = () => {
    document.body.style.overflow = 'hidden'
    mobileMenu.classList.remove('translate-x-full')
}

const setupHideMenu = () => {
    let prevScrollPos = window.pageYOffset || document.documentElement.scrollTop

    window.addEventListener('scroll', function () {
        const currentScrollPos =
            window.pageYOffset || document.documentElement.scrollTop

        if (
            currentScrollPos > menu.getBoundingClientRect().height / 2 &&
            currentScrollPos > prevScrollPos
        ) {
            menu.classList.add('is-away')
        } else {
            menu.classList.remove('is-away')
        }

        prevScrollPos = currentScrollPos
    })
}

const initMenu = () => {
    scroll()
    window.addEventListener('scroll', () => scroll())

    document.querySelector('.js-menu-open').onclick = openMenu
    document.querySelector('.js-menu-close').onclick = closeMenu

    document.querySelectorAll('header a').forEach((el) => {
        el.onclick = closeMenu
    })

    window.addEventListener('resize', () => {
        if (window.matchMedia('(min-width: 1024px)').matches) {
            closeMenu()
        }
    })

    setupHideMenu()
}

export { initMenu }
