const freePositions = () => {
    const all = document.querySelectorAll('.js-carrer-position')

    if (all.length == 0) {
        return
    }

    const update = ({ el, text, button }) => {
        if (!text) {
            text = el.querySelector('.js-carrer-position-text')
        }
        if (!button) {
            button = el.querySelector('.js-carrer-position-button')
        }

        if (el.classList.contains('open')) {
            el.style.maxHeight = `${
                button.scrollHeight + text.scrollHeight
            }px`
        } else {
            el.style.maxHeight = `${button.scrollHeight}px`
        }
    }

    all.forEach((el) => {
        const text = el.querySelector('.js-carrer-position-text')
        const button = el.querySelector('.js-carrer-position-button')

        el.style.maxHeight = `${button.scrollHeight}px`
        text.classList.remove('hidden')

        window.addEventListener('resize', () => {
            update({ el, button, text })
        })

        button.addEventListener('click', () => {
            el.classList.toggle('open')
            update({ el, button, text })

            if (!el.classList.contains('open')) {
                return
            }

            all.forEach((e) => {
                if (e == el) {
                    return
                }

                e.classList.remove('open')
                update({ el: e })
            })
        })
    })
}

const initCareer = () => {
    freePositions()
}

export { initCareer }
