import Glide from '@glidejs/glide'

const initOurBuildingsSlider = () => {
    const slider = document.querySelector('.js-our-buldings-slider')

    if (!slider) return

    const items = slider.querySelectorAll('.glide__slide').length

    const glide = new Glide(slider, {
        type: 'carousel',
        perView: 3,
        bound: true,
        autoplay: 10000,
        gap: 22,
        breakpoints: {
            1200: {
                perView: 2
            },
            1024: {
                perView: 3
            },
            850: {
                perView: 2
            },
            550: {
                perView: 1
            }
        }
    })

    const resize = () => {
        if (items <= glide.settings.perView) {
            glide.pause()
            glide.disable()
            slider.classList.add('disabled')
            return
        }

        glide.play()
        glide.enable()
        slider.classList.remove('disabled')
    }

    glide.on('resize', function () {
        resize()
    })

    glide.on('mount.after', function () {
        resize()
        slider.classList.add('mounted')
    })

    glide.mount()
}

const initOverlaySliders = () => {
    const sliders = document.querySelectorAll('.js-overlay-slider')

    if (sliders.length == 0) {
        return
    }

    const glides = {}

    const showOverlay = (el, index) => {
        el.classList.remove('translate-x-full')
        el.classList.remove('opacity-0')

        if (!index) return

        const glide = glides[el]

        if (!glide) {
            return
        }

        glide.update({ animationDuration: 0 })
        glide.go(`=${index}`)
        glide.update({ animationDuration: 400 })
    }

    const hideOverlay = (el) => {
        el.classList.add('translate-x-full')
        el.classList.add('opacity-0')
    }

    document.querySelectorAll('button[data-overlay-target]').forEach((el) => {
        el.onclick = () =>
            showOverlay(
                document.querySelector(el.dataset.overlayTarget),
                el.dataset.slideTarget
            )
    })

    sliders.forEach((el) => {
        el.querySelector('.js-overlay-slider-close').addEventListener(
            'click',
            () => {
                hideOverlay(el)
            }
        )

        const current = el.querySelector('.js-overlay-slider-current')
        const progress = el.querySelector('.js-overlay-slider-progress')
        const max = Number(el.dataset.max)

        const glide = new Glide(el, {
            type: 'carousel',
            perView: 1,
            autoplay: 10000,
            gap: 20,
            bound: true
        })

        glide.on('run', () => {
            current.innerHTML = (glide.index + 1).toString().padStart(2, 0)
            progress.style.width = `${((glide.index + 1) / max) * 100}%`
        })

        glide.mount()

        glides[el] = glide
    })
}

const initReviewsSlider = () => {
    const slider = document.querySelector('.js-reviews-slider')

    if (!slider) {
        return
    }

    const glide = new Glide(slider, {
        type: 'slider',
        perView: 1,
        autoplay: 10000,
        gap: 20,
        animationDuration: 700,
        breakpoints: {
            1024: {
                animationDuration: 400
            }
        }
    })
    glide.mount()
}

const initSubsidiesSlider = () => {
    const slider = document.querySelector('.js-subsidies-slider')

    if (!slider) {
        return
    }

    const glide = new Glide(slider, {
        type: 'slider',
        perView: 1,
        autoplay: 10000,
        gap: 20,
        animationDuration: 700,
        breakpoints: {
            1024: {
                animationDuration: 400
            }
        }
    })
    glide.mount()
}

const initEventsSlider = () => {
    const slider = document.querySelector('.js-events-slider')

    if (!slider) {
        return
    }

    const current = slider.querySelector('.js-events-slider-current')
    const progress = slider.querySelector('.js-events-slider-progress')
    const max = Number(slider.dataset.max)

    const glide = new Glide(slider, {
        type: 'carousel',
        perView: 3,
        autoplay: 10000,
        gap: 30,
        animationDuration: 400,
        startAt: 0,
        bound: true,
        breakpoints: {
            1024: {
                perView: 2
            },
            400: {
                perView: 1
            }
        }
    })

    glide.on('mount.after', function () {
        slider.classList.add('mounted')
    })

    glide.on('run', () => {
        current.innerHTML = (glide.index + 1).toString().padStart(2, 0)
        progress.style.width = `${((glide.index + 1) / max) * 100}%`
    })

    glide.mount()
}

const initPostSlider = () => {
    const slider = document.querySelector('.js-post-slider')

    if (!slider) {
        return
    }

    const current = slider.querySelector('.js-post-slider-current')
    const progress = slider.querySelector('.js-post-slider-progress')
    const max = Number(slider.dataset.max)

    const glide = new Glide(slider, {
        type: 'carousel',
        perView: 3,
        autoplay: 10000,
        gap: 30,
        animationDuration: 400,
        startAt: 0,
        bound: true,
        breakpoints: {
            1024: {
                perView: 2
            },
            400: {
                perView: 1
            }
        }
    })

    glide.on('mount.after', function () {
        slider.classList.add('mounted')
    })

    glide.on('run', () => {
        current.innerHTML = (glide.index + 1).toString().padStart(2, 0)
        progress.style.width = `${((glide.index + 1) / max) * 100}%`
    })

    glide.mount()
}

const initSliders = () => {
    initOurBuildingsSlider()

    initOverlaySliders()

    initReviewsSlider()
    initEventsSlider()

    initPostSlider()
    initSubsidiesSlider()
}

export { initSliders }
