const preSelectForm = () => {
    const form = document.querySelector('.js-footer-form')

    if (!form) {
        return
    }

    const value = form.dataset.selectValue

    if (!value) {
        return
    }

    const selectInput = form.querySelector('select')

    selectInput.value = value
}

const initFooter = () => {
    preSelectForm()
}

export { initFooter }
