/**
 * BaseTheme rework now uses javascript version ES6
 * ------
 *
 * Functions and Variables are no longer global and need to be imported
 * ( How to prepare function for export can be seen in `utils.js` )
 */
import { initUtils } from './utils'
import { initMenu } from './menu'
import { initScrollText } from './scrollText'
import { initCountUp } from './countUp'
import { initSliders } from './glide'
import { initHeroTexts } from './heroTexts'
import { initMenuHighlighter } from './menuHighlighter'
import { initEventPopup } from './popup'
import { initCareer } from './career'
import { initFooter } from './footer'
import { initOurBuildings } from './our_buildings'
/**
 * This function or DOMContentLoaded event is necesary for compiling to work
 *
 * Standalone functions will be removed on compile, so function that is called only in onclick event for example will be removed
 */
window.onload = () => {
    initUtils()
    initMenu()
    initScrollText()
    initCountUp()

    const startTexts = initHeroTexts()
    initEventPopup(startTexts)

    initSliders()
    initMenuHighlighter()
    initCareer()

    initFooter()

    initOurBuildings()
}
