const initOurBuildings = () => {
    const buildings = document.querySelector('.js-our-buildings')
    const button = document.querySelector('.js-our-buildings-button')

    if (!button) {
        return
    }

    const wrapper = buildings.parentElement

    const resize = () => {
        wrapper.style.maxHeight = `${buildings.scrollHeight}px`
    }

    resize()

    window.addEventListener('resize', () => resize())
    button.addEventListener('click', () => {
        const hiddenBuildings = Array.from(
            document.querySelectorAll('.js-our-buildings .hidden')
        )

        for (let i = 0; i < Math.min(6, hiddenBuildings.length); i++) {
            const el = hiddenBuildings[i]
            el.classList.remove('hidden')
            resize()
        }

        if (hiddenBuildings.length > 6) {
            return
        }

        button.style.display = 'none'
    })
}

export { initOurBuildings }
