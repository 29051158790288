function initHeroTexts() {
    if (!document.querySelector('.js-heroTexts')) {
        return () => {}
    }

    const animatedSpan = document.querySelector('.js-heroTexts .animated')
    const texts = JSON.parse(
        document.querySelector('.js-heroTexts').dataset.subtitles
    )
    texts.shift()
    const letterDelayIn = 120
    const letterDelayOut = 90
    const iterationDelay = 2000

    function removeWordFromBack(callback) {
        const originalText = animatedSpan.textContent.trim()
        let removeIndex = originalText.length - 1

        const interval1 = setInterval(() => {
            if (removeIndex >= 0) {
                animatedSpan.textContent = originalText.substring(
                    0,
                    removeIndex
                )
                removeIndex--
            } else {
                clearInterval(interval1)
                if (typeof callback === 'function') {
                    callback()
                }
            }
        }, letterDelayOut) // Adjust the interval to control the animation speed
    }

    function fillWordFromFront(text, callback) {
        const newText = text.trim()
        let addIndex = 0
        const interval2 = setInterval(() => {
            if (addIndex < newText.length) {
                animatedSpan.textContent += newText[addIndex]
                addIndex++
            } else {
                clearInterval(interval2)
                if (typeof callback === 'function') {
                    callback()
                }
            }
        }, letterDelayIn) // Adjust the interval to control the animation speed
    }

    function animateTextLoop(texts) {
        let currentIndex = 0

        function nextIteration() {
            const currentText = texts[currentIndex]

            removeWordFromBack(() => {
                fillWordFromFront(currentText, () => {
                    if (currentIndex == texts.length - 1) {
                        return
                    }

                    currentIndex = (currentIndex + 1) % texts.length
                    setTimeout(nextIteration, iterationDelay) // 2-second pause between iterations
                })
            })
        }

        nextIteration()
    }

    // Example usage

    return () => {
        setTimeout(() => animateTextLoop(texts), iterationDelay)
    }
}

export { initHeroTexts }
